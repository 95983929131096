
.cursor-pointer {
    cursor: pointer;
}
/*********************************login******************************************************/
.left_login{
    width: 50%;
    background-color:#262626;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.right_login{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
}
.login_wrapper{
    display: flex;
    width: 100%;
    background: #fff;
    height: 100%;
    flex-direction: row;
    font-family: "LibreFranklin-Medium";
    overflow: auto;
}
.login_logo{
    width: 140px;
}
.img_logo{
    max-width: 100%;
    display: block;
    margin: auto;
}
.login_header{
    margin-bottom: 0;
    text-align: center;
}
.form_login{
    width: 100%;
    padding: 8px 20px 8px 10px;
    font-size: 16px;
    font-weight: 400;
    background: 0 0;
    border: 0;
    border-bottom: 2px solid var(--theme-black);
    outline: 0;
    border-radius: 0;
    margin-bottom:20px;
}
.login_forget{
    color: var(--theme-black);
}
.login_btn{
    width: 100%;
    padding: 10px 20px;
    border-radius: 5px;
    border: 0;
    outline: 0;
    box-shadow: none;
    background-color: var(--theme-color);
    color: var(--theme-white);
    margin-bottom: 20px;
}
.right_loginhead{
    font-size: 25px;
    margin-bottom: 15px;
}
.right_login_inner,.right_login_forget{
    width: 350px;
    text-align: center;
}
.form_div{
    position: relative;
}
.backto_login{
    text-align: right;
}
.backto_loginspan{
    text-decoration: underline;
    cursor: pointer;
    color: #007bff;
}
.inputError{
    color: red;
}
/*********************************login******************************************************/

/*********************************dashboard**************************************************/
.dashboard_header{
    display: flex;
    align-items: center;
}
.dashboard_logo{
    width: 140px;
}
.dashbaord_logoimg{
    max-width: 100%;
}
.dashboard_headerlist{
    margin-left: auto;
}
.dashboard_list{
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding:8px 0px;
}
.dashboard_listsvg{
    font-size: 35px;
    color: var(--theme-color);
}
.dashboard_subul{
    position: absolute;
    top: 100%;
    background: var(--theme-color);
    width: 100%;
    min-width: 120px;
    right: 0;
    display: none;
}
.dashboard_list:hover .dashboard_subul{
    display: block;
    z-index: 999;
}
.dashboard_admintext{
    font-family: "LibreFranklin-Bold";
    margin-right: 20px;
    color: var(--theme-color);
}
.submenu_litag{
    color: #fff;
    display: block;
    width: 100%;
    padding: 8px 10px;
}
.submenu_litag:hover{
    color: var(--theme-white);
    text-decoration: none;
}
.main_header{
    background-color: #fff;
    box-shadow: 0px 0px 6px 0px #a99f9f;
}
.dashboard_listwrapper .nav_ul{
    box-shadow: 0px 5px 10px 0px #c9c0c0;
    padding: 50px;
    align-items: center;
    justify-content: center;
}
.dahsbaord_listitem{
    border: 2px solid var(--theme-color);
    margin-right: 10px;
    padding: 8px 20px;
    border-radius: 5px;
    color: var(--theme-color);
    font-family: "LibreFranklin-Medium";
    min-width: 150px;
    display: block;
    text-align: center;
    text-decoration: none;
    margin-bottom: 10px;
}
.dahsbaord_listitem:hover{
    background:var(--theme-color);
    color: #fff;
    text-decoration: none;
}
.dashboard_wrapper{
    display: flex;
    height: 100%;
    flex-direction: column;
    background: #f7f7f7;
    overflow: auto;
}
.dashboard_listwrapper{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}
/*********************************dashboard**************************************************/

/*********************************Cms**************************************************/
.adminIconMain {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 8;
    /* display: none; */
}
.adminIconMainTop {
    top: 35px;
}
/* .adminIconMainBtm {
    top: -35px;
} */
.editIcon {
    background: #fff;
    display: block;
    padding: 5px 10px;
    border-radius: 5px 0 0 5px;
    box-shadow: 0px 3px 5px #929292;
    cursor: pointer;
    margin-bottom: 4px;
}
.editIcon img {
    width: 20px;
    display: block;
    opacity: 1;
    cursor: pointer;
}
.adminIconLeft {
    position: absolute;
    top: 5px;
    left: 1px;
    z-index: 9;
    text-align: center;
}
.draftSpan, .rejectButton, .approveButton {
    width: 75px;
    display: block;
    padding: 3px 10px;
    cursor: pointer;
    border-radius: 5px;
    color: #fff;
    margin-bottom: 5px;
    text-align: center;
    font-size: 14px;
}
.draftSpan {
    background-color: #edaf00;
}
.rejectButton {
    background: #ff0000;
}
.rejectCommentText {
    color: #fff;
    font-size: 13px;
    padding: 5px;
    background: rgba(0, 0, 0, 0.55);
    width: auto;
    margin-bottom: 5px;
    text-align: left;
    display: inline-block;
    line-height: 16px;
    max-height: 100%;
    overflow-y: auto;
    float: left;
}
.approveButton {
    background: #40a700;
    z-index: 1;
    position: relative;
}
.editActive .edit-banner-section, .editActive .edit-section {
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 9;
}
.imageEditListBanner {
    padding: 10px;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.editActive .editInput, .editActive .editTextInput, .editActive .editPanelButton, .editActive .imageEditList, .editActive .upload-label-txt, .editActive .edit-banner-section, .editActive .edit-section,.editActive .editInput {
    display: block !important;
}
.editActive .fileUpload label {
    display: inline-block;
    text-decoration: none;
    background: #edaf00;
    color: #fff;
    position: relative;
    padding: 7px 25px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    cursor: pointer;
}
.bannerImageAlert {
    font-size: 13px;
    display: block;
    color: #000000;
    font-weight: 500;
}
.edit-banner-section, .edit-section {
    position: absolute;
    top: 0px;
    bottom: 0;
    left: 0%;
    right: 0;
    text-align: center;
    padding: 20px 50px;
    height: 343px;
}
.fileUpload input[type="file"] {
    display: none;
}
.bannerSizeText {
    margin-right: 10px;
}
.banner img {
    max-width: 100%;
    margin: auto;
    width: 100%;
}
.imgPreview {
    width: 100px ;
    display: block;
    margin: 10px auto;
}
body .editInput, body .editTextInput {
    width: 100%;
    height: 35px;
    padding: 2px 10px;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid #c3c3c3;
    outline: 0;
    border-radius: 0;
    margin: 0 auto 5px;
    display: none;
}
.editPanelButton {
    margin: 20px 0;
    text-align: center;
    display: none;
}
.editActive .adminIconMain, .editActive h2, .editActive h5, .editActive p, .editActive .adminIconLeft, .editActive ol, .editActive h4, .editActive ul, .editActive .toggle_circle .ion-circled, .edit-banner-section, .edit-section {
    display: none;
}
.bannerTextAlert {
    display: block;
    color: #ff0000;
    font-weight: 400;
}
.main_wrapper_cms .adminIconMain,.main_wrapper_cms .adminIconLeft{
    display: block;
}
.adminIconLeft,.adminIconMain{
    display: none;
}
.location_edit_div{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.location_edit_divmain{
    display: flex;
    /* align-items: center; */
    gap: 5px;
    margin-top: 10px;
}
.locator_left .imageEditListBanner{
    width: 100%;
    height: 100%;
    padding: 0;
}
.locator_left .editInput{
    width: 100%;
    margin: 0;
    margin-top: auto;
}
.locator_left .fileUpload label{
    margin-bottom: 0;
}
.locator_left .bannerSizeTextEdit{
    display: block;
    font-size: 12px;
    margin: 5px 0;
}
.location_edit_divmain .fileUpload label{
    font-size: 14px;
    font-family: "LibreFranklin-Medium";
    font-weight: unset;
}
.main_wrapper_cms .locator_listul {
    /* padding-top: 70px; */
    position: relative;
}
.service_position textarea{
    padding: 0px 10px;
    font-size: 13px;
    width: 100%;
}
.locator_listul .editIcon.DeleteIcon,.servic_liwidth .editIcon.DeleteIcon,.servic_liwidthlg  .editIcon.DeleteIcon{
    padding: 0;
    background: transparent;
    box-shadow: none;
}
.locator_listul .editIcon.DeleteIcon img,.servic_liwidth .editIcon.DeleteIcon img,.servic_liwidthlg  .editIcon.DeleteIcon img{
    width: 16px;
}
.editIcon-not-click {
    opacity: 0;
}
.cms_searchicon{
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 18px;
    color: #555;
}
.cms_searchinput{
    display: block;
    width: 100%;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;border-radius: 10px 0px 0px 10px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    padding: 10px 10px 10px 35px;
    outline: 0;
    box-shadow: none;
}
.input-group-text{
    background-color: var(--theme-black);
    color: var(--theme-white);
    border-radius: 0px 10px 10px 0px;
    border: 0;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
}
.cms_input_div{
    display: flex;
    width: 400px;
    justify-content: flex-end;
}
.cms_dashboad_div{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin: 30px 0;
    position: relative;
}
.inner_cms_div{
    width: calc(33.33% - 20px);
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 7px 0px #8b8484;
    display: flex;
    flex-direction: column;
}
.inner_cms_img{
    max-width: 100%;
    border-radius: 10px 10px 0 0;
    margin-bottom: 10px;
}
.inner_cms_divhead{
    font-family: "LibreFranklin-SemiBold";
    margin-bottom: 10px;
    font-size: 18px;
}
.inner_cms_div_left{
    width: 100%;
}
.inner_cms_div_right {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.inner_cms_divpara{
    margin-bottom: 0;
    color: #000;
    font-family: "LibreFranklin-Medium";
    font-size: 16px;
}
.inner_cms_div_imgdiv{
    max-width: 100%;
    opacity: .5;
}
.inner_cms_div_img{
    width: 30px;
    height: 30px;
    border: 1px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inner_cms_div_connt {
    padding: 12px 15px;
    position: relative;
    padding-right: 50px;
}
.inner_cms_siteId {
    color: #000;
    font-size: 12px;
    background-color: #e3e3e3;
    width: fit-content;
    border-radius: 5px;
    padding: 2px 10px;
    margin-bottom: 5px;
}
.locator_cms_ancher{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 6px;
    cursor: pointer;
}
.input_edit_option_span{
    font-family: "LibreFranklin-Medium";
    margin-bottom: 5px;
}
.service_locator_option{
    position: relative;
}
.main_wrapper_cms .service_locator_option{
    padding-bottom: 50px;
}
.main_wrapper_cms .category_option {
    padding-bottom: 50px; 
}
/* .main_wrapper_cms .locator_option .adminIconLeft{
    top: 100%;
    left: 20px;
    margin-top: -80px;
} */
.service_position .adminIconLeft{
    display: flex;
    align-items: center;
    gap: 10px;
    /* margin-top: -35px; */
}
.service_position .rejectSection{
    display: flex;
    align-items: center;
    gap: 10px;
}
/*********************************Cms**************************************************/

/*********************************Breadcrumkb**************************************************/
.breadcrumb{
    background-color: transparent;
    margin-bottom: 0;
}
[aria-label="breadcrumb"]{
    background-color: transparent;
}
.breadcrumb-item+.breadcrumb-item::before {
    content: "";
    background-image: url('../images/downarrow.png');
    background-repeat: no-repeat;
    background-size: 15px;
    width: 14px;
    height: 14px;
    transform: rotate(270deg);
    margin-top: 4px;
    margin-right: 10px;
}
/*********************************Breadcrumkb**************************************************/

/*********************************View Report**************************************************/
.view_reportsheading{
    font-size: 25px;
    font-family: "LibreFranklin-Regular";
    text-align: left;
    margin-bottom: 0px;
}
.text-trnfrmReport{
    text-transform: uppercase;
}
.view_report_form{
    width: 100%;
    display: block;
    padding: 6px 10px;
    font-weight: 400;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: 0;
    box-shadow: none;
    position: relative;
}
.view_reports_form{
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 40px;
}
.input_form{
    width: calc(16.666667% - 10px);
    position: relative;
}
.date_imgechange{
    position: absolute;
    right: 10px;
    width: 20px;
    top: 50%;
    transform: translateY(-50%);
    filter: grayscale(1);
}
.input_form_btngroup{
    width: 33.33333%;
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}
.input_form_bttn{
    background-color: var(--theme-color);
    padding: 6px 10px;
    border: 0;
    border-radius: 5px;
    color: #fff;
}
.input_form_bttn:hover{
    text-decoration: none;
    color: #fff;
}
.heading_wrapper_div{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 98%;
}
/*********************************View Report**************************************************/

/*********************************pagination**************************************************/
.react_pagination{
    display: flex;
    align-items: center;
}
.pagination {
    margin-left: auto;
    flex-wrap: wrap;
}
.pagination a {
    color: black;
    float: left;
    padding: 4px 12px;
    text-decoration: none;
}
.pagination li.active {
    background-color: #645b5b;
    border-radius: 5px;
}
.pagination li.active a {
    color: white;
}
/*********************************pagination**************************************************/

/*********************************Reports**************************************************/
.report_table_div{
    font-family: "LibreFranklin-Regular";
    font-size: 14px;
}
.report_table_div th{
    white-space: nowrap;
}
.table_select_check{
    display: flex;
    flex-direction: column;
}
.table_select_checkbtn{
    border: 2px solid red;
    padding: 2px 20px;
    border-radius: 5px;
    font-size: 14px;
    color: red;
    background: transparent;
}
/*********************************Reports**************************************************/

/*********************************File Upload**************************************************/
.file_upload_div{
    border: 2px dashed var(--theme-black);
    padding: 6px;
    border-radius: 10px;
    margin-bottom: 10px;
}
.file_upload_innerdiv{
    /* border: 1px solid rgb(0 0 0 / 13%); */
    background: #fff;
    box-shadow: 0px 0px 25px #00000029;
    border-radius: 10px;
    position: relative;
}
.file_upload_divtag{
    width: 35px;
}
.file_upload_img{
    max-width: 100%;
    height: auto;
}
.span_drag_drop{
    color: #322F6E;
    font-size: 14px;
    opacity: 0.48;
    padding-right: 18px;
    padding-left: 18px;
    font-family: "LibreFranklin-Regular";
}
.span_drag_drop_or{
    color: #322F6E;
    font-size: 14px;
    font-family: "LibreFranklin-Medium";
}
.file_upload_btndiv{
    padding-left: 15px;
}
.file_upload_btn{
    background-color: var(--theme-black);
    color: #fff;
    border-radius: 10px;
    font-size: 13px;
    border: none;
    padding: 13px 30px;
    font-family: "LibreFranklin-Medium";
}
.file_upload_input{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.file_upload_inputfile{
    position: relative;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 18px 0;
}
.table_fileupload_btn{
    display: flex;
    align-items: center;
    gap: 10px;
}
.file_upload{
    background-color: var(--theme-black);
    border-color: var(--theme-black);
    outline: 0;
    color: #fff;
    border: 1px solid transparent;
    padding: 6px 18px;
    font-size: 14px;
    border-radius: 4px;
}
.file_upload_cancel{
    background-color: var(--theme-color);
    border-color: var(--theme-black);
    outline: 0;
    color: #fff;
    border: 1px solid transparent;
    padding: 6px 18px;
    font-size: 14px;
    border-radius: 4px;
}
.file_upload_table_div{
    padding: 20px;
    font-family: "LibreFranklin-Regular";
    font-size: 14px;
}
.file_upload_table_div th {
    white-space: nowrap;
}
.file_upload_table_responsive{
    overflow: auto;
    max-height: 600px;
    padding: 20px 0px 0;
}
.file_upload_table_tag{
    /* height: 900px; */
    background-color: var(--theme-white);
}
.fileupload_table {
    max-height: 900px;
}
.fileupload_headdiv,.fileupload_bodydiv{
    display: flex;
}
.fileupload_head{
    color: #000;
    opacity: 0.48;
}
.fileupload_head,.fileupload_body{
    width: 20%;
    font-family: "LibreFranklin-Medium";
    padding: 10px 20px 10px 10px;
    display: flex;
    align-items: center;
}
/* .fileupload_bodytext_uname{
    width: 100px;
} */
.fileupload_delteImgdiv{
    width: 70px;
    justify-content: flex-end;
}
.file_upload_imgtag{
    width: 20px;
    cursor: pointer;
}
.fileupload_bodydiv{
    background-color: #fff;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 10px;
    margin: 15px 5px;
}
.fileupload_body_head_span{
    display: none;
}
/*********************************File Upload**************************************************/
.rdw-editor-wrapper {
    box-sizing: content-box;
    width: 100%;
    border: 1px solid #c3c3c3;
}
.rdw-editor-wrapper ul {
    display: block;
}
.main_wrapper_cms .main_banner_height {
    min-height: 360px;
}
.main_banner_list {
    position: relative;
    /* min-height: 300px; */
}
.main_wrapper_cms .main_banner_list {
    min-height: 360px;
}
/* .div_approve_adminicon{
    bottom: 100% !important;
    left: 20px !important;
    margin-top: 0px !important;
    top: unset !important;
} */
.public-DraftStyleDefault-block {
    margin: 0 !important;
}
.edit-banner-section{
    background: #fff;
    box-shadow: 1px 1px 5px 0px rgb(193 180 180);
}
.edit_location_store_div textarea,.edit_location_store_div input{
    font-size: 13px;
}
.edit_location_store_div textarea{
    height: 80px;
}
.locator_left .adminIconLeft{
    top: 20px;
    left: unset;
    display: flex;
    align-items: center;
    gap: 10px;
}
.locator_left .rejectSection{
    display: flex;
    align-items: center;
    gap: 10px;
}
.main_wrapper_cms .locator_left{
    margin-top: 60px;
    padding-top: 80px;
}
.upload_image_tag{
    background-color: rgba(221, 221, 221,.3);
    color: #000;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.seo_main_div{
    position: relative;
}
.seo-main, .addSectionMain {
    position: absolute;
    left: unset;
    top: 0;
    z-index: 99;
}
.seoTopButton {
    background: #edaf00;
    color: #000;
    border-radius: 0 0 5px 0;
    font-size: 14px;
    padding: 5px 25px;
    box-shadow: 0px 3px 5px #929292;
    cursor: pointer;
    display: inline-block;
}
.seoSection.editActive {
    width: 300px;
    background-color: #a2a2a2;
    padding: 10px;
    z-index: 2;
    position: relative;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 50%);
}
.seoSection.editActive {
    width: 300px;
    background-color: #a2a2a2;
    padding: 10px;
    z-index: 2;
    position: relative;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 50%);
}
body .saveButton, body .cancelButton, body .saveDraft {
    display: inline-block;
    text-decoration: none;
    background: #478800;
    color: #fff;
    position: relative;
    padding: 7px 10px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    margin: 0 0 0 10px;
}
body .saveDraft {
    background: #edaf00;
}
.service_option_edit .editPanelButton .saveDraft, .editPanelButton .saveButton, .editPanelButton .cancelButton,body .saveDraft{
    padding: 5px 4px;
    font-size: 10px;
}
body .cancelButton {
    background: #7d7d7d;
}
.seo_main_div .editTextInput {
    height: 135px;
    padding-top: 10px;
    resize: none;
}

.socialLinksAdmin {
    position: relative;
    background: #e1dddd;
    margin-right: 15px;
    margin-left: 15px;
    padding: 25px 25px;
    font-size: 14px;
    margin: 15px;
    text-align: left;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
}
.socialLinksAdmin-heading {
    position: absolute;
    top: -6%;
    left: 26px;
    background: #fff;
    border-radius: 5px;
    font-size: 17px;
    padding: 2px 20px;
    border: 1px solid #c9c8c8;
}
.socialLinksAdmin label {
    background: #c9c8c8;
    border: 1px solid #c1bcbc;
    height: 100%;
    margin: 0 0;
    vertical-align: top;
    padding: 3px 5px;
    width: 40%;
    position: relative;
    min-height: 35px;
    display: flex;
    align-items: center;
}
.socialLinksList {
    height: 100%;
    padding: 2px 10px;
    font-size: 15px;
    font-weight: 400;
    background: #fff;
    outline: 0;
    border-radius: 0;
    width: 60%;
    margin: 0 11px 10px 0;
    vertical-align: top;
    border: 1px solid #dedede;
    border-left: 0;
    border: 1px solid #c5c5c5;
}
.head-social-help1 img {
    position: absolute;
    right: 15px;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
}
.socialLinksCommon {
    display: contents;
}
.head-social-help, .head-social-help1 {
    position: relative;
    display: inline-block;
    width: 100%;
    display: flex;
    height: 100%;
}
.editNav {
    padding: 5px 0;
}
body .backLink {
    float: left;
    margin: 5px 0 0 15px;
    color: #ffc912;
    font-weight: 600;
    text-decoration: none;
}
body .logoutLink {
    float: right;
    margin: 0px 15px 0 0;
    color: #f93c28;
    font-weight: 600;
    text-decoration: none;
}
.inner_cms_div_connt_footer{
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;    
    flex-wrap: wrap;
    gap: 4px;
    margin-top: auto;
    padding-bottom: 10px;
}
body .rdw-editor-main {
    height: auto;
}
.ribbon{
    width: auto;
    background: #ffc107;
    color: #212529;
    text-align: center;
    padding: 2px 10px;
    font-size: 12px;
    border-radius: 10px;
    margin: 0 15px 0 0;
    display: inline-block;
}
.main_heading_dashboard_wrapper{
    font-size: 25px;
    font-family: "LibreFranklin-Medium";
    text-align: left;
    margin-bottom: 0px;
    margin-top: 40px;
}
#centreHead{
    height: auto;
}
.main_wrapper_cms .service_position .locator_service_option{
    padding-top: 40px;
}
.category_store_div .adminIconMain{
    padding-right: 0px;
}
/* .main_wrapper_cms .adminIconMain.adminIconMainTop{
    padding-right: 15px;
} */
/* .main_wrapper_cms .category_store_div{
    padding-top: 80px;
} */
.service_locator_option .adminIconMain{
    right: -30px;
}
.main_wrapper_cms .category_store_div .rejectSection textarea{
    width: 100%;
}
.main_wrapper_cms .category_store_div  .adminIconLeft{
    bottom: 0;
    top: unset;
}
.main_wrapper_cms .img_cat{
    padding-bottom: 85px;
    position: relative;
}
.categories_wrapper {
    position: absolute;
    right: 0;
    top: 32px;
}
.categories_wrapper.editActive .edit-banner-section {
    left: auto;
    right: 0;
}
.seo_main_div .seo-main{
    right: 15px;
    top: 0px;
}
/* .main_wrapper_cms .main_banner_list:nth-child(odd) .adminIconMain.adminIconMainTop{
    padding-right: 15px;
} */
/* .edit-section{
    width: 100%;
    height: 100%;
} */
.main_wrapper_cms .main_banner .gapY_10{
    min-height: 200px;
}
.main_banner_list .edit-banner-section{
    height: 100%;
}
.main_wrapper_cms .service_options{
    padding-top: 70px;
}
.service_options .adminIconMain{
    top: 50px;
}
.service_option{
    position: relative;
}
.adminIconMainBtm {
    top: -30px;
}
.admin_icons_left{
    padding-left: 15px;
    flex-wrap: wrap;
}
.react_icon_wrap{
    flex-wrap: wrap;
}
.admin_icon_left_position{
    padding-left: 30px;
}
/* add-edit locations */
.location_header{
    font-size: 25px;
    font-family: "LibreFranklin-SemiBold";
    margin-bottom: 0;
}
.location-bttn{
    width: 15%;
    font-size: 15px;
    padding: 8px 14px;
    border-radius: 5px;
    border: 0;
    outline: 0;
    box-shadow: none;
    background-color: var(--theme-color);
    color: var(--theme-white);
}
.btn-save,.btn-cancel{
    min-width: 100px;
    font-size: 15px;
    padding: 8px 14px;
    border-radius: 5px;
    border: 0;
    outline: 0;
    box-shadow: none;
    background-color: var(--theme-color);
    color: var(--theme-white);
}
.btn-cancel{
    background-color: #000;
}
.location-bttns{
    width: unset;
}

.loder-wrapper {
    /* background-image: url("../images/loading.png"); */
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    background-color: rgb(255 255 255 / 80%);
    background-position: center;
    z-index: 9999;
}
.closed-btn .input-group-text:hover {
    background-color: rgba(255, 0, 0, 0.5);
}

.banner-edit-wrapper {
    position: relative;
    background-image: url("../images/banner-deafult.jpg");
    min-height: 300px;
}
.banner-edit-wrapper .slick-arrow {
    top: 50%;
}
.banner-edit-wrapper .slick-next {
    right: -30px;
}
.banner-edit-wrapper .slick-prev {
    left: -30px;
}
button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}
.loder-list{
    margin: auto;
    margin-top: 30px;
}
.loder-list-img{
    opacity: .5;
    opacity: .5;
    margin: auto;
    display: block;
}
.loder-list-add-edit{
    margin-top: 100px;
}
.inner_cms_siteCode{
    color: #0b3981;
    background: #cfe6eb;
}