:root {
    --theme-black: #000;
    --theme-white: #fff;
    --theme-color: #FF7451;
    --theme-sub-color: #006666;
    --footer-txt-color: #66765f
}

@font-face {
    font-family: "Lato-Black";
    src: url("../fonts/Lato-Black.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato-Bold";
    src: url("../fonts/Lato-Bold.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato-Light";
    src: url("../fonts/Lato-Light.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato-Regular";
    src: url("../fonts/Lato-Regular.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "LibreFranklin-Light";
    src: url("../fonts/LibreFranklin-Light.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "LibreFranklin-Regular";
    src: url("../fonts/LibreFranklin-Regular.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "LibreFranklin-Medium";
    src: url("../fonts/LibreFranklin-Medium.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "LibreFranklin-Black";
    src: url("../fonts/LibreFranklin-Black.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "LibreFranklin-SemiBold";
    src: url("../fonts/LibreFranklin-SemiBold.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: "LibreFranklin-SemiBold";
    src: url("../fonts/LibreFranklin-SemiBold.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "LibreFranklin-Bold";
    src: url("../fonts/LibreFranklin-Bold.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter-Bold";
    src: url("../fonts/Inter-Bold.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Inter-Regular";
    src: url("../fonts/Inter-Regular.ttf");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body,
html {
    width: 100%;
    height: 100%;
}

body {
    background-color: var(--theme-white);
    color: var(--theme-black);
    font-family: "LibreFranklin-Regular";
    font-size: 15px;
    transition: .3s ease-in-out;
    overflow-x: hidden;
}

#root {
    height: 100%;
}

a {
    color: #007bff;
    cursor: pointer;
}

.nav-link {
    cursor: pointer;
}

button {
    cursor: pointer;
}

svg {
    cursor: pointer;
}

.main-head {
    color: #FF7451;
    /* font-family: "Lato-Bold"; */
    font-family: "LibreFranklin-SemiBold";
    font-size: 26px;
    margin-bottom: 40px;
}
.button-theme {
    background: var(--theme-sub-color);
    box-shadow: 0px 0px 7.37414px rgba(0, 0, 0, 0.25);
    border-radius: 25px 0px;
    border: 0;
    color: #fff;
    font-family: "Lato-Regular";
    outline: 0;
    padding: 14px 25px;
    width: 100%;
    text-decoration: none;
    display: block;
    text-align: center;
    font-size: 16px;
}
.button-theme:hover {
    color: #fff;
    text-decoration: none;
}
.button-sm{
    padding: 10px 25px;
}

/* ***********************************header**********************************************/
.topleft_header {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.logoTop {
    margin: 0 17%;
    padding: 0px;
}

.top_header_container_div {
    padding-left: 0px;
    display: flex;
    align-items: center;
}

.logoImg {
    width: 110px;
}

.main_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.topleft_header {
    width: 140px;
}

.header_logoimg {
    max-width: 100%;
}

.flex_align_center {
    display: flex;
    align-items: center;
}

.top_header_right {
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-right: 20px;
}

.top_header_right li .nav-link {
    color: var(--theme-white);
    padding: 24px 15px;
}

.top_header_location {
    align-items: center;
}

.top_header_right li .border_btn {
    border-radius: 0;
    background: 0 0;
    border: 1px solid rgba(255, 255, 255, .431372549);
    color: rgba(255, 255, 255, .7215686275);
    padding: 4px 9px;
    font-size: 14px;
    margin-left: 20px;
}
.globe_tag,.globe_tag:hover{
    padding: 5px 25px;
    color: var(--footer-txt-color);
    text-decoration: none;
    font-size: 13px;
    display: flex;
    align-items: center;
}
.main_menu {
    background-color: var(--theme-white);
    display: block;
    position: relative;
}
.menu_bar_close,.menu_listing_arrow,.inner_menu_down_icon{
display: none;
}
.menu_nav_tag {
    padding: 8px 15px;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #66765f;
    font-family: 'LibreFranklin-Regular';
    text-decoration: none;
    position: relative;
}
.menu_nav_tag:hover {
    color: #333333;
    text-decoration: none;
}
.menu_show_hide{
    display: none;
}
.inner_menus_mobiles{
    display: none;
}
.main_menu li .bg_theme {
    background-color: var(--theme-color);
    color: var(--theme-white);
    padding: 2px 8px;
    margin-left: 10px;
}
.header_social_div{
    display: flex;
    flex-wrap: wrap;
    margin: 20px 1px 20px
}
.header_social_div .nav_link_tag,.header_social_div .nav_link_tag:hover{
    padding: 13px 30px;
    flex: 0 1 33.33%;
    text-align: center;
    border: 1px solid;
    border-color: #e8e8e1;
    margin-left: -1px;
    margin-bottom: -1px;
}
.himalaya_globel,.header_social_div{
    display: none;
}
.header_social_div .nav_link_tag svg{
    width: 20px;
    height: 20px;
}
.header_social_div .nav_link_tag svg path{
    fill: #66765f;
    color: #66765f;
}
.drop_down_li a::after,
.drop_down_li span::after {
    content: "";
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-bottom: 0;
    border-left: 4px solid transparent;
    margin-left: 5px;
}

.middle_right_menu {
    align-items: center;
    margin-left: auto;
}

.cart_svg {
    margin-right: 5px;
    display: inline-block;
    width: 20px;
    height: 20px;
}

.cart_style {
    position: relative;
}

.total_items {
    width: 18px;
    position: absolute;
    background-color: #28a745;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    top: -8px;
    right: -5px;
}

.middle_right_menu .nav-item:first-child .nav-link::after {
    content: '';
    width: 1px;
    background: #000;
    right: 0;
    position: absolute;
    height: 12px;
}

body .main_wrapper .toggle_menu {
    display: none;
    border: 0;
    margin-left: 5px;
}

.toggle_svg {
    width: 21px;
    height: 21px;
    display: inline-block;
}

.flex_container {
    display: flex;
}

.nav_align {
    align-items: center;
}

.main_menu .footer_menu {
    display: none;
}

.top_header .nav-link {
    font-size: 14px;
    color: var(--theme-white);
    font-family: 'Lato-Light';
    padding: 4px 1rem;
}

.top_header svg {
    color: #444444
}

.top_header_imgsrc {
    width: 25px;
    padding-right: 20px;
}

.manin_menu_logo {
    width: 110px;
    display: flex;
    align-items: center;
    margin: 0px 10px 0 0;
}

.manin_menu_logo_img {
   max-width: 100%;
}
.body_wrapper{
    position: relative;
}
.body_wrapper::before{
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e6e6e6;
    opacity: .6;
    z-index: 9999;
}
.gapY_10 {
    gap: 10px 0px;
}
.menu_bar_close svg{
    color: #66765f;
    width: 22px;
    height: 22px;
    margin-right: 3px;
}
.overflow_div{
    display: flex;
}
.manin_menu_hemalaya {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 25px;
}
.inner_menus{
    width: calc(20% - 16px);
    display: flex;
    flex-direction: column;
}
.menu_icon {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 3px;
}
.menu_list_single{
    position: relative;
}
.menu_bar {
    width: 24px;
    height: 2px;
    background: #66775f;
    margin-bottom: 6px;
}
.menu_bar:last-child {
    margin-bottom: 0;
}
.menu_bar2 {
    width: 20px;
}
.dropdown_single .inner_menus{
    width: 100%;
}
.dropdown_single .inner_menus_head,.dropdown_single .inner_menus_head:hover,.dropdown_single .dropdown_link,.dropdown_single .dropdown_link:hover{
    display: block;
    padding: 8px 15px;
    white-space: nowrap;
}

.appear_animation{
    opacity: 0;
    transform: translateY(60px);
}
.appear_delay_1{
    transition: transform 1s cubic-bezier(.165,.84,.44,1) .1s,opacity 1s cubic-bezier(.165,.84,.44,1) .2s;
}
.appear_delay_2{
    transition: transform 1s cubic-bezier(.165,.84,.44,1) .22s,opacity 1s cubic-bezier(.165,.84,.44,1) .32s;
}
.appear_delay_3{
    transition: transform 1s cubic-bezier(.165,.84,.44,1) .28s,opacity 1s cubic-bezier(.165,.84,.44,1) .38s;
}
.appear_delay_4{
    transition: transform 1s cubic-bezier(.165,.84,.44,1) .34s,opacity 1s cubic-bezier(.165,.84,.44,1) .44s;
}
.appear_delay_5{
    transition: transform 1s cubic-bezier(.165,.84,.44,1) .4s,opacity 1s cubic-bezier(.165,.84,.44,1) .5s;
}
.appear_delay_6{
    transition: transform 1s cubic-bezier(.165,.84,.44,1) .46s,opacity 1s cubic-bezier(.165,.84,.44,1) .56s;
}
.appear_delay_7{
    transition: transform 1s cubic-bezier(.165,.84,.44,1) .52s,opacity 1s cubic-bezier(.165,.84,.44,1) .62s;
}
.appear_delay_8{
    transition: transform 1s cubic-bezier(.165,.84,.44,1) .58s,opacity 1s cubic-bezier(.165,.84,.44,1) .68s;
}
.menu_list_tag:hover .appear_animation{
    opacity: 1;
    transform: none;
}


.manin_menu_menubar_ul {
    display: none;
}
.top_announcement{
    background-color: #ff7451;
    padding: 8.5px 0;
    text-align: center;
    display: flex;
    justify-content: end;
    align-items: center;
}
.announcement_link,.announcement_link:hover{
    color: #fff;
    font-size: 15px;
    text-decoration: none;
}
.announcement_text{
    font-family: "LibreFranklin-Medium";
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    word-spacing: 3px;
}
.announcement_link_text{
    padding-left: 5px;
    text-decoration: underline;
    font-size: 13px;
}

.top_globel{
    border-bottom: 1px solid #f7f7f7;
}
.inner_menus_head,.inner_menus_head:hover{
    padding: 4px 0;
    color: #333333;
    font-size: 13px;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 5px;
    letter-spacing: 3px;
    display: block;
    font-family: "LibreFranklin-Medium";
}
.dropdown_link,.dropdown_link:hover{
    padding: 2px 0;
    font-size: 15px;
    color: #333333;
    text-decoration: none;
    display: block;
}
.icon_icon_user{
    width: 30px;
    height: 30px
}
.icon_icon_user path{
    fill: #fff;
    stroke-width: 4px;
    stroke: #66765f;
}
/* ***********************************header**********************************************/

/* ***********************************slider**********************************************/
.main_banner .slick-next {
    right: 5px;
    z-index: 9;
}

.main_banner .slick-prev {
    left: 5px;
    z-index: 9;
}
.main_banner .slick-arrow {
    top: 50%;
    top: 50%;
    border-radius: 50%;
    background-color: rgb(255,255,255,0.4);
}
.main_banner .slick-arrow:hover, .main_banner .slick-arrow:focus {
    background-color: rgb(255,255,255,0.6);
}
.slick-slide img {
    max-width: 100%;
    margin: auto;
}

.main_banner {
    position: relative;
    background-image: url("../images/banner-deafult.jpg");
    min-height: 300px;
}
.main_banner_list_img {
    width: 100%;
}
body .main_wrapper .slick-dots li button {
    height: 3px;
    width: 25px;
    border-radius: 0;
    padding: 0;
    background-color: gray;
}

body .main_wrapper .slick-dots li.slick-active button {
    background-color: #69b33e;
}

.slick-dots li button:before {
    display: none;
}

.slick-dots button {
    bottom: -5px;
}

/* body .slick-list{
    padding: 40px 0 0px 0;
} */
body .slick-arrow::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    position: relative;
}

.slick-arrow.slick-next::before {
    border-right: 2px solid black;
    border-top: 2px solid black;
    transform: rotate(45deg);
    right: 3px;
}

.slick-arrow.slick-prev::before {
    border-left: 2px solid black;
    border-bottom: 2px solid black;
    transform: rotate(45deg);
    left: 3px;
}

body .slick-next {
    right: 5px;
}

.public-DraftStyleDefault-block {
    margin: 0;
}

/* ***********************************slider**********************************************/

/* ***********************************Footer**********************************************/
.main_footer {
    background-color: #eef2f0;
    margin-top: auto;
}
.footer_container{
    background: transparent;
    padding-top: 30px;
    padding-bottom: 10px
}
.footer_contact_para .nav_link_tag{
    display: inline !important;
}
.nav_link_tag,.nav_link_tag:hover {
    color: var(--footer-txt-color);
    margin-bottom: 5px;
    padding: 4px 0;
    display: block;
    font-size: 13px;
    text-decoration: none;
}
.footer_nav{
    list-style: none;
}
.footer_nav_desk{
    margin-bottom: 20px;
    color: var(--footer-txt-color);
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 4px
}
.footer_contact_para strong{
    font-family: "LibreFranklin-SemiBold";
    font-weight: unset;
}
.footer_bottom_div{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.footer_bottom_inner{
    width: calc(33.33% - 14px);
}
.footer_social{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    gap: 15px;
}
.footer_social svg{
    width: 24px;
    height: 24px;
    fill: var(--footer-txt-color);
}
.footer_social svg path{
    fill: var(--footer-txt-color);
}
.footer_contact_para{
    margin: 0 0 15px;
    color: var(--footer-txt-color);
    font-size: 13px;
}
.footer_contact_para .nav_link_tag{
    display: inline-block;
    padding: 0;
    margin-bottom: 0;
    position: relative;
    text-decoration: none;
    border-bottom: 2px solid;
    border-color: #66765f1a;
}
.himalaya_company{
    margin: 0 0 15px;
    display: block;
    font-family: 'LibreFranklin-SemiBold';
    font-size: 14px;
    color: #66765f;
}
.collapsible-content__inner p a:focus:after, .collapsible-content__inner p a:hover:after {
    width: 100%;
}
.footer_contact_para .nav_link_tag:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0%;
    border-bottom: 2px solid;
    border-bottom-color: #66765f;
    border-bottom-color: var(--colorFooterText);
    transition: width .5s ease;
}
.footer_contact_para .nav_link_tag:hover::after{
    width: 100%;
}
.footer_nav_mobile{    
    display: flex;
    align-items: center;
    display: none;
}
.footer_nav_mobile svg{
    margin-left: auto;
    width: 18px;
    height: 18px;
    transition: .5s;
}
.svg_rotate{
    transform: rotate(180deg);
}
/* ***********************************Footer**********************************************/

/* ******************************Store .locator_main_div********************************************/
.store_location {
    padding: 50px 0px 30px;
    margin: 0px 20px;
}

.store_logolocation {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.store_span {
    font-size: 27px;
    /* margin-left: 10px; */
    margin-bottom: 0;
}

.store_locationform{
    margin-top: 30px;
}
.store_locationform, .explore-main {
    width: 480px;
}
.store_locationform_list {
    width: 80%;
    display: flex;
}
.store_form {
    margin-bottom: 40px;
    position: relative;
    width: 100%;
}
.store_locationform_list .store_form {
    padding-right: 5%;
}

.form_control {
    background: #FFFFFF;
    box-shadow: 0px 0px 11px rgb(0 0 0 / 25%);
    border-radius: 10px;
    width: 100%;
    padding: 25px 33px 25px 20px;
    border: 0;
    outline: 0;
    color: #000;
    appearance: none;
    font-size: 18px;
}



select.form_control {
    background-image: url('../images/downarrow.png');
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: right 10px top 22px;
    cursor: pointer;
}

.store_form img,
.updown_arrowstyle {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
}

.store_form:last-child {
    margin-bottom: 0;
}

.row_storeborder {
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 10px;
}


.locate_responseimg {
    margin-bottom: 20px;
}

.locate_responseimg .locator_listimg {
    display: none;
}
.mapboxgl-popup h3 {
    font-size: 14px;
}
.mapboxgl-map {
    min-height: 280px;
}

/* ********************************Store Location******************************************/
/*  */

.view_location {
    margin-top: 25px;
}
.view_locationimgdiv {
    background: #fff;
    border: 1px solid #e5e0e0;
    border-radius: 5px;
    border-radius: 6.16px;
    box-shadow: 0 0 8px rgb(0 0 0 / 25%);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px 10px 13px;
}

.view_locationimg {
    max-width: 100%;
    margin: auto;
    display: block;
    margin-bottom: 15px;
    border-radius: 5px;
}

.view_location_head {
    color: #000;
    font-family: "LibreFranklin-Bold";
    font-size: 20px;
    margin-bottom: 10px;
    padding: 0 10px;
}

.view_location_para {
    margin-bottom: 18px;
    font-family: "LibreFranklin-Medium";
    font-size: 17px;
    padding: 0px 10px 0px 10px;
}

.viewlocation_gap {
    margin-bottom: 20px;
}
.view_locatior_heading {
    font-family: "LibreFranklin-SemiBold";
    font-size: 28px;
    margin-bottom: 30px;
}
.view_locationpara {
    margin-bottom: 0;
    text-transform: capitalize;
}

.view_location_navg {
    background-color: var(--theme-color);
    width: 100%;
    color: var(--theme-white);
    border-radius: 5px;
    padding: 14px 10px;
    position: relative;
    display: block;
    text-align: center;
    text-decoration: none;
    margin-top: auto;
    font-size: 17px;
}

.view_location_navg:hover {
    color: var(--theme-white);
    text-decoration: none;
}

.view_location_navgspan {
    color: #fff;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    font-size: 16px;
    font-size: 25px;
}
/*  */
/* *************************************Map********************************************/
body .gmap_hspadmin>div {
    width: calc(100% - 82px) !important;
    height: calc(100% - 30px) !important;
    top: 15px;
}
.gmap_hspadmin > div > div{
    border-radius: 10px;
}
.gmap_locator_head{
    display: none;
}
.gmap_location .placeDiv {
    z-index: 9999;
    position: absolute;
}

.gmap_location_container {
    position: relative;
}
.gmap_locator_heading{
    color: var(--theme-sub-color);
    font-family: "LibreFranklin-SemiBold";
    font-size: 17px;
    margin-bottom: 20px;
    line-height: 1;
    cursor: auto;
}
.map-iframe-wrapper {
    border-radius: 10px;
    border: 0;
}
.gmap_loction_mapdiv {
    padding: 10px 7px 0px; 
    background: #fff;
    border: 0;
    border-radius: 10px;
    box-shadow: 0 0 8px rgb(0 0 0 / 25%);
    /* max-width: 40%; */
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 300px;
}
body .gmap_location_warapper .gmap_hspadmin>div {
    width: calc(100% - 20px) !important;
    height: calc(100% - 30px) !important;
    top: 15px;
}

.gmap_location .placecard__container {
    box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
    max-width: 330px;
    width: 100%;
    background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box;
    border-radius: 2px 2px 2px 2px;
    font: normal normal normal normal 11px / normal Roboto, Arial, sans-serif;
    margin: 10px;
    padding: 9px 4px 9px 11px;
    overflow: hidden;
}

.gmap_location .placecard__left {
    float: left;
    width: 75%;
}

.gmap_location .placecard__business-name {
    cursor: default;
    height: 19px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
    perspective-origin: 100px 9.5px;
    transform-origin: 100px 9.5px;
    font: normal normal 500 normal 14px / normal Roboto, Arial;
    overflow: hidden;
    margin: 0;
}

.gmap_location .placecard__info {
    color: rgb(91, 91, 91);
    cursor: default;
    height: auto;
    width: 200px;
    column-rule-color: rgb(91, 91, 91);
    perspective-origin: 100px 16px;
    transform-origin: 100px 16px;
    border: 0px none rgb(91, 91, 91);
    font: normal normal normal normal 12px / normal Roboto, Arial;
    margin: 6px 0px 0px;
    outline: rgb(91, 91, 91) none 0px;
}

.gmap_location .placecard__view-large {
    display: block;
    margin-top: 10px;
    color: rgb(58, 132, 223);
    text-decoration: none;
}
.gmap_location_warapper {
    padding-left: 80px;
    padding-right: 40px; 
}
.gmap_wrapper {
    /* margin-bottom: 20px; */
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 100%;
    height: 100%;
    padding: 15px 10px;
}
/* *************************************Map********************************************/
.location_direction_border{
    display: none;
}
/* *************************************Locator********************************************/
.locator_main_div {
    padding: 34px 30px 50px 30px;
}

.locator_stores {
    color: #949494;
    padding: 10px 0px;
    font-size: 18px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    line-height: 24px;
}

.locator_lefthead {
    color: #000;
    font-size: 32px;
    margin-bottom: 0px;
    word-break: break-word;
    font-family: "LibreFranklin-Bold";
}

.locator_listli {
    display: flex;
    align-items: center;
    width: 33.33%;
    position: relative;
    margin-bottom: 10px;
}

.locator_width {
    width: 100%;
    margin-bottom: 40px;
    padding-left: 7px;
}

.locator_width .locator_listlileft {
    box-shadow: 0px 0px 0px 7px #FF7451;
}

.locator_widthlast {
    width: 65%;
}

.locator_innerwidth {
    width: 35%;
}

.locator_listlileft {
    width: 40px;
    height: 40px;
    border: 1px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px
}

.locator_listlileftimg {
    max-width: 100%;
}

.locator_listlirightpara {
    margin-bottom: 0;
    font-size: 13px;
    font-family: "LibreFranklin-Medium";
}

.locator_listliright {
    width: calc(100% - 35px);
    padding-left: 15px;
}

.locator_row_listitem {
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: -295px;
}



/* *************************************Locator********************************************/

/* ***********************************serviec option**************************************/
.service_optionhead {
    color: var(--theme-sub-color);
    font-family: "LibreFranklin-SemiBold";
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1;
    cursor: auto;
}
.service_positions{
    position: relative;
}
.service_options.servicePadd {
    padding-top: 10px;
}

.service_options {
    background: #FFFFFF;
    border-radius: 7px;
    padding: 0px 12px;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 0;
    padding-bottom: 20px;
}

.service_options ul {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.service_categories_avilable li{
    width: 33.33%;
}
.service_options ul li {
    position: relative;
    padding-left: 10px;
    font-family: 'LibreFranklin-Medium';
    font-size: 12.5px;
    padding-right: 25px;
    margin-bottom: 15px;
}

.service_options ul li::after {
    content: '';
    display: block;
    width: 3px;
    height: 3px;
    background-color: #000;
    top: 8px;
    position: absolute;
    left: 0px;
    opacity: .6;
    border-radius: 50%;
}
.locator_himalaya {
    padding-bottom: 45px;
}
.locator_left {
    position: relative;
    padding: 35px 35px;
    padding-bottom: 30px;
}
.locator_row_right{
    padding-right: 30px;
}
.gmap_locator_row_left{
    padding-left: 48px;
}
.locator_listimg {
    max-width: 100%;
    border-radius: 10px;
    display: block;
}

.locator_phone_link {
    color: #007bff;
    cursor: pointer;
    text-decoration: none;
}

.locator_phone_link:hover {
    text-decoration: none;
}


.service_options .service_ul {
    padding: 0px 10px;
}


.loactor_img_col {
    padding-left: 25px;
    position: relative;
}

/* ***********************************serviec option**************************************/
/* ***************************************Enquire*****************************************/
.enquire_div .form_control,
.enquire_div .form_control::placeholder {
    color: var(--theme-black);
}
.enquire_div:last-child .enquire_divpadding{
    margin-bottom: 25px;
}
.enquiry_padding{
    padding-left: 48px;
}
.form_control.textarea {
    resize: none;
}
.enquiry_btn {
    background: var(--theme-sub-color);
    box-shadow: 0px 0px 7.37414px rgba(0, 0, 0, 0.25);
    border-radius: 25px 0px;
    border: 0;
    color: #fff;
    font-family: "Lato-Light";
    font-size: 20px;
    outline: 0;
    padding: 14px 25px;
    width: 100%;
    text-decoration: none;   
    display: block;    
    text-align: center;
    font-size: 16px;
}
.enquiry_btn:hover{
    text-decoration: none;  
    color: #fff;
}

.enquire_div .form_control.textarea,
.enquire_div .form_control.textarea::placeholder {
    color: #a39f9f;
}

.enquire_div {
    background: #FFFFFF;
    border: 1px solid #FF7451;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
}

.enquiry_popup {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.enquiry_innerpopyp {
    width: 400px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 8px;
    text-align: center;
    padding: 0px;
    font-family: "LibreFranklin-Regular";
    font-size: 16px;
    position: relative;
}

.enquiry_innerbody {
    padding: 20px 20px;
    border-bottom: 1px solid #f0f0f0;
}

.enquiry_innerfooter {
    text-align: center;
    padding: 4px 10px;
}

.enquiry_close_btn {
    border: 0;
    font-size: 12px;
    padding: 6px 25px;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    margin-top: 10px;
}

.enquiry_inner_close {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    width: 18px;
    height: 18px;
    stroke-width: 3;
    opacity: .6;
}

/* ***************************************Enquire*****************************************/


.cursor_none {
    cursor: default;
}

/* ***************************************reviews*****************************************/
.reviews_reviewBox {
    background: #fff;
    padding: 14px 15px;
    margin: auto;
    border-bottom: 1px solid #E0E0E0
}
.reviews_btn{
    background: var(--theme-sub-color);
    box-shadow: 0px 0px 7.37414px rgba(0, 0, 0, 0.25);
    border-radius: 25px 0px;
    border: 0;
    color: #fff;
    font-family: "Lato-Light";
    font-size: 20px;
    outline: 0;
    padding: 14px 25px;
    width: 100%;
    text-decoration: none;
    display: block;
    text-align: center;
    font-size: 16px;
}
.reviews_btn:hover{
    text-decoration: none;
    color: #fff;
}
.reviews_mainWrapper{
    /* max-height: 550px;
    overflow: auto;
    margin-bottom: 20px;
    background: #FFFFFF;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding-right: 10px; */
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    max-height: 100%;
    padding: 10px 6px 1px 0;
    margin-bottom: 25px;
}
.reviews_mainWrapperinner{
    overflow: auto;
    height: 100%;
    width: 100%;
    max-height: 635px;
    overflow: auto;
    margin-bottom: 20px;
    background: #FFFFFF;
    padding-right: 10px;
    padding-left: 10px;
    scrollbar-width: thin;
    scrollbar-color: #D9D9D9 #fff;
    -moz-scrollbar-width: 1px;
}
.reviews_mainWrapperinner::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  .reviews_mainWrapperinner::-webkit-scrollbar-track {
    background: #fff;
  }
  .reviews_mainWrapperinner::-webkit-scrollbar-thumb {
    background: #D9D9D9;
  }
  .reviews_mainWrapperinner::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
.reviews_starRate {
    display: inline-block;
    margin-left: auto;
}
.reviews_starRate img{
    max-width: 20px;
    margin-right: 4px;
}
.reviews_reviewBox p {
    color: #949494;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 0.8rem;
    padding-right: 12px;
}

.reviews_reviewBox small {
    color: rgba(0, 0, 0, .4);
}

.reviews_div {
    /* padding-top:35px; */
    padding-right: 0px;
}

.reviews_reviewBox_inner:last-child .reviews_reviewBox{
    border-bottom: 0;
}
.reviews_mainWrapper.reviews_empty_reviews{
    padding: 68px 20px 35px 30px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
}
.reviews_empty_reviews_para{
    font-family: 'LibreFranklin-Regular';
    font-size: 26px;
    margin-bottom: 45px;
}
body .slick-prev,
body .slick-next {
    z-index: 9999;
    width: 30px;
    height: 30px;
    /* transform: rotate(90deg); */
    top: unset;
    left: unset;
    border: 0;
    top: -22px;
}

.reviews_imageText {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.reviews_imageTextDiv {
    display: flex;
    align-items: center;
    width: calc(100% - 120px);
}

.reviews_imgreview {
    display: inline-block;
    height: 50px;
    width: 50px;
}

.reviews_imgreview img {
    width: 100%;
}

.reviews_imageDate {
    color: #000;
    margin-left: 10px;
    width: calc(100% - 60px);
}

.enquire_div .form_control {
    font-size: 16px;
}


body .cursor_default {
    cursor: auto;
}

/* .main_container{
    padding: 0px 30px;
} */

.arrow_slick_custom:first-child svg {
    margin-left: 20px;
}

.arrow_slick_custom svg {
    width: 30px;
    display: inline-block;
    margin-left: 5px;
    height: 30px;
}
.react-stars span {
    margin-left: 0px;
    font-size: 22px !important;
}

.react-stars span svg {
    cursor: default;
}

/* ***************************************reviews*****************************************/

/* ***************************************tags*****************************************/
.tags-wrapper {
    padding-left: 0;
}
.tags-list-sub {
    display: inline-block;
    background: #FFFFFF;
    border: 1px solid var(--theme-sub-color);
    border-radius: 2.5px;
    box-sizing: border-box;
    padding: 5px 10px;
    font-size: 14px;
    color: #333;
    font-family: "LibreFranklin-Medium";
    min-width: 150px;
    text-align: center;
    margin: 0 16px 16px 0;
}
/* ***************************************tags*****************************************/

/* ***************************************social media*****************************************/
.social-media-list {
    display: flex;
}
.social-media-img {
    background: #FFFFFF;
    border: 1.25px solid var(--theme-sub-color);
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-right: 24px;
}

/* ***************************************social media*****************************************/

/* ***************************************youtube*****************************************/
.youtube-wrapper {
    margin-bottom: 50px;
}
.youtube-iframe {
    width: 100%;
    height: 256px;
    background: rgba(0, 0, 0, 0.21);
    border-radius: 12px;
}
/* .youtube-wrapper .slick-slide{
    width: 420px;
} */
.youtube-wrapper .slick-track{
    display: flex !important;
    align-items: center;
    gap: 30px; 
}
body .youtube-section .slick-prev, body .youtube-section .slick-next{
    bottom: -50px;
    top: unset;
}
body .youtube-section .slick-prev{
    right: 40px;
}
.youtube_diw{
    display: flex;
    overflow: auto;
    flex-direction: row;
    flex-wrap: nowrap;
    max-height: initial;
    gap: 34px;
    box-shadow: none;
    scrollbar-width: thin;
    scrollbar-color: #D9D9D9 #fff;
    -moz-scrollbar-width: 1px;
}
.youtube_diw::-webkit-scrollbar {
    width: 4px;
    height: 0px;
  }
  .youtube_diw::-webkit-scrollbar-track {
    background: #fff;
  }
  .youtube_diw::-webkit-scrollbar-thumb {
    background: #D9D9D9;
  }
  .youtube_diw::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
.youtube-subList >div{
    width: 100% !important;
    height: auto !important;
}
.youtube-subList iframe{
    border-radius: 12px;
    width: 420px;
    height: 235px;
}
/* ***************************************youtube*****************************************/


/* ***************************************podcasts*****************************************/
.podcasts-wrapper {
    margin-bottom: 30px;
}
.podcasts-wrapper .slick-list{
    margin: 0 -15px;
}
.podcasts-wrapper .slick-slide{
    padding: 0 15px;
    height: inherit;
}
.podcasts-wrapper .slick-track{
    margin-left: 0;
    display: flex;
}
.podcasts-wrapper .slick-slide > div{
    height:calc(100% - 10px);
}
body .podcasts-section .slick-prev, body .podcasts-section .slick-next{
    bottom: -50px;
    top: unset;
}
body .podcasts-section .slick-prev{
    right: 40px;
}
.podcasts-subList {
    border-radius: 12px;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0px 0px 8px 0px #00000040;
    margin: 7px 0;
    margin-bottom: 0;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    margin-bottom: 10px;
}
.podcasts-subList .button-sm{
    margin-top: auto;
}
.podcasts-img{
    border-radius: 6px;
    margin: unset !important;
    /* width: 320px; */
}
.podcasts-head {
    font-size: 16px;
    font-family: "Lato-Bold";
    color: var(--theme-sub-color);
    margin: 15px 0 5px;
    text-align: center;
}
/* ***************************************podcasts*****************************************/

/* ***************************************Blog*****************************************/
.blog-wrapper {
    margin-bottom: 50px;
    margin-top: 30px;
}
.blog-wrapper .slick-list{
    margin: 0 -15px;
}
.blog-wrapper .slick-slide{
    padding: 0 15px;
    height: inherit;
}
.blog-wrapper .slick-slide >div{
    height: calc(100% - 30px);
}
.blog-subList-a{
    display: flex;
    flex-direction: column;
}
.blog-wrapper .slick-track{
    margin-left: 0;
    display: flex;
}
body .blog-section .slick-prev, body .blog-section .slick-next{
    bottom: -30px;
    top: unset;
}
body .blog-section .slick-prev{
    right: 40px;
}
.blog-subList {
    border-radius: 4px;
    overflow: hidden;
    padding: 0;
    border: 1px solid #EEF1F4;
    margin: 7px 0;
    height: 100%;
}
.blog-post-det {
    margin-bottom: 0;
    padding-left: 10px;
    min-height: 25px;
} 
.blog-post-det span {
    color: #333;
    font-size: 9px;
    position: relative;
    text-transform: lowercase;
    word-spacing: 4px;
}
.blog-post-det-date{
    padding-left: 10px;
    color: #333;
    font-size: 9px;
    position: relative;
    text-transform: lowercase;
    word-spacing: 4px;
    margin-bottom: 6px;
    display: inline-block;
}
.blog-post-det-readtime::before {
    content: '\00B7';
    margin: 0 0.6em 0 0.3em;
}
.blog-content {
    text-align: center;
    padding: 10px;
}
.blog-subList {
    background-color: #fafdff;
}
.blog-subList:hover {
    background: #F1F4F7;
    text-decoration: none;
    box-shadow: 0 0 36px rgba(0,0,0,0.06);
}
.blog-subList:hover .blog-subList-a{
    text-decoration: none;
}
.blog-head {
    font-size: 18px;
    font-family: "LibreFranklin-Medium";
    color: #222;
    margin: 0 0 7px;
}
.blog-subhead {
    text-transform: uppercase;
    font-size: 9px;
    color: #555;
}
.blog-text {
    font-size: 12px;
    color: #000;
}
/* ***************************************Blog*****************************************/

/* footer mobile */
.nav_sidemenu{
    width:100%;
    list-style: none;
    
}
.nav_sidemenu span{
    width:100%;
}
.submenu__isopen {
    letter-spacing: .3em;
    font-size: .9em;
}
/* .main_footer_desktop_menu{
    display: block;
} */
.mobile_menu{
    display: none;
    padding: 0 0 15px;
    color: #66765f;
    font-size: 14px;
    font-family: "LibreFranklin-Medium";
    margin-bottom: 0;
}
.mobile_menu_infor{        
    padding: 0 0 15px;
        color: #66765f;
        font-size: 13px;
        font-family: 'LibreFranklin-Regular';
}
/* footer mobile */
.mapboxgl-canvas-container.mapboxgl-touch-zoom-rotate.mapboxgl-touch-drag-pan .mapboxgl-canvas{
    border-radius: 10px;
}
.input-section{
    width: 100%;
    height: 30px;
    border: 1px solid #d1d1d1;
    padding: 10px
}
.list-nodata{
    text-align: center;
    font-size: 18px;
}
.store-enquiry {
    border-radius: 10px;
    width: 100%;
    padding: 23px 33px 23px 20px;
    margin-top: 30px;
    font-size: 18px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #ffc9bb;
    box-shadow: 0px 2px 8px 0px rgb(99 99 99 / 30%);
}
.img-enquiry-form{
    transform: rotate(270deg);
}
.enquiry-outer-popup{
    background-color: rgb(0 0 0 / 15%);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    visibility: hidden;
}
.enquiry-outer-popup.show{
    visibility: visible;
}
.enquiry-inner-div{
    width: 560px;
    /* margin: 62px auto; */
    border: 1px solid #FF7451;
    background: #fff;
    padding: 23px;
    border-radius: 10px;
    transition: .5s;position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    transition: .3s ease-in-out;
}
.enquiry-outer-popup.show .enquiry-inner-div{
    transform: translateX(-50%) translateY(-50%);
}
.enquiry-outer-popup.hide .enquiry-inner-div {
    transform: translateX(-50%) translateY(100%);
  }
.enquiry-inner-div .form_control{
    border-radius: 7px;
    margin-bottom: 25px;
    padding: 12px 22px;
    box-shadow: 0px 0px 3.83px 0px #00000040;

}
.enquiry-inner-div .form_control::placeholder{
    color: #000;
    font-size: 16px;
}
.error-head {
    font-size: 2.5rem;
    font-family: "LibreFranklin-SemiBold";
}
.error-lead {
    font-size: 1.25rem;
    font-weight: 300;
}