@media (min-width: 1600px) {
    .main-wrapper{
        width: 83.333333%;
    }
}
@media (max-width: 1300px) {
    .main_menu li .nav-link {
        padding: 10px 8px;
        font-size: 12px;
    }

    .top_header_right li a {
        font-size: 13px;
    }

    .footer_container {
        padding: 30px 20px;
    }

    .blog-head {
        font-size: 15px;
    }
}

@media(max-width:1280px) {
    .podcasts-head {
        font-size: 15px;
    }
}

@media (max-width: 1199px) {

    .locator_row_right {
        padding-right: 15px;
    }

    .gmap_locator_row_left {
        padding-left: 15px;
    }

    body .gmap_hspadmin>div {
        width: calc(100% - 50px) !important;
    }

    .gmap_location_warapper {
        padding-left: 15px;
        padding-right: 15px;
    }

    .store_locationform, .explore-main {
        width: 100%;
    }
}

@media(max-width:1024px) {
    .footer_container {
        padding: 30px 30px;
    }

    .footer_bottom {
        padding: 0px 30px;
    }

    .podcasts-head {
        font-size: 14px;
    }
}

@media (max-width: 991px) {
    .footer_container {
        padding: 30px 5px;
    }

    .footer_bottom {
        padding: 0px 5px;
    }

    .blog-content {
        padding-top: 0;
    }

    .blog-head {
        font-size: 14px;
    }

    .podcasts-head {
        font-size: 13px;
    }

    .menu_nav_tag {
        padding: 8px 8px;
    }

    .manin_menu_logo {
        margin-right: 8px;
    }

    .inner_menus {
        width: calc(33.33% - 14px);
    }

    .footer_container_div .nav-item span {
        padding: 10px 10px;
    }

    .main_footer {
        padding: 0px 0px;
    }

    body {
        font-size: 12px;
    }

    body .main_wrapper .toggle_menu {
        display: block;
    }

    .nav_align {
        flex-direction: column;
    }

    .footer_container_div,
    .top_header_container_div {
        max-width: 100%;
    }

    .nav_align {
        align-items: flex-start;
    }

    .main_menu.show {
        height: 100%;
    }

    .middle_right_menu li:first-child a::after {
        display: none;
    }

    .main_menu li a.bg_theme {
        margin-left: 0;
        padding: 2px 8px;
    }

    .footer_container_padding {
        padding-bottom: 20px;
    }

    .footer_container_padding:last-child {
        padding-bottom: 0;
    }

    .main_menu .footer_menu {
        display: block;
        background-color: transparent;
    }

    .footer_menu li .nav-link {
        color: #000;
        padding: 8px 0;
    }

    .footer_menu .container {
        padding: 0;
    }

    .footer_menu {
        display: none;
    }

    .locator_main_div {
        padding: 40px 0px;
        padding-top: 20px;
    }

    .locator_row_listitem {
        margin-top: 0;
    }

    body .gmap_hspadmin>div {
        min-height: 230px;
        position: static;
        width: calc(100% - 50px) !important;
        height: calc(100% - 20px) !important;
        top: 10px;
    }

    .gmap_wrapper {
        height: 260px;
    }

    .locator_row_listitem {
        box-shadow: none;
    }

    .service_options {
        padding-left: 0;
        padding-right: 0;
    }

    .gmap_locator_head {
        display: block;
        margin-bottom: 5px;
    }

    .locator_left {
        padding: 0;
    }

    .locator_stores {
        border-bottom: 1px solid #D6D6D6;
    }

    .locator_lefthead,
    .locator_stores,
    .locator_listul,
    .locator_option,
    .service_locator_option,
    .gmap_locator_head,
    .gmap_locator_row_left,
    .reviews_div,
    .enquiry_padding,
    .youtube-wrapper,
    .tags_div,
    .social_div,
    .podcasts-wrapper,
    .blog-wrapper {
        padding-left: 20px;
        padding-right: 20px;
    }

    .locator_himalaya {
        padding-bottom: 40px;
    }

    .location_direction_border {
        display: block;
        border-bottom: 1px solid #D6D6D6;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 0;
        border-top: 0;
    }

    .locator_listul {
        border-bottom: 1px solid #D6D6D6;
        padding-bottom: 20px;
        margin-bottom: 30px;
    }

    .enquire_div {
        border: 0;
        padding: 0;
    }

    .enquiry_main_head.invisible {
        visibility: visible !important;
    }

    .reviews_div {
        padding-right: 15px;
    }

    .reviews_div {
        margin-bottom: 20px !important;
    }

    .social-media-img {
        width: 55px;
        height: 55px;
        margin-right: 20px;
    }

    .social-media-img img {
        width: 25px;
        height: 25px;
    }

    .tags-wrapper {
        margin-bottom: 20px;
    }

    .locator_row_right {
        padding-right: 0;
        padding-left: 0;
    }

    .main_banner {
        min-height: 170px;
    }
    .store_span{
        font-size: 20px;
    }
}

@media (min-width:769px) {
    .dropdown_megamenu {
        position: absolute;
        width: 100%;
        left: 0;
        background: #fff;
        z-index: 9;
        padding: 39px 40px;
        top: 100%;
        box-shadow: 0 10px 20px #00000017;
        padding-top: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        line-height: 1.8;
        opacity: 0;
        transform: none;
        opacity: 0;
        transition: all .3s cubic-bezier(.2, .06, .05, .95);
        transition-delay: .3s;
        visibility: hidden;
    }

    .menu_list_tag:hover .dropdown_megamenu {
        opacity: 1;
        visibility: visible;
        transition: all .6s cubic-bezier(.2, .06, .05, .95);
        transition-delay: 0s;
        transform: translateY(-6px);
        transition: all .3s cubic-bezier(.2, .06, .05, .95);
    }

    .dropdown_single {
        position: absolute;
        left: 0;
        margin: 0;
        z-index: 999999999;
        display: block;
        background-color: #fff;
        padding: 10px 0 5px;
        box-shadow: 0 10px 20px #00000017;
        min-width: 225px;
        transform: none;
        opacity: 0;
        transition: all .6s cubic-bezier(.2, .06, .05, .95);
        visibility: hidden;
        transform: translate3d(0, -12px, 0);
    }

    .menu_list_single:hover .dropdown_single {
        visibility: visible;
        transform: translateZ(0);
        transition: all .3s cubic-bezier(.2, .06, .05, .95);
        opacity: 1;
    }

    .menu_nav_tag:after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 100%;
        margin: 0 15px;
        border-bottom: 2px solid;
        border-bottom-color: #66765f;
        border-bottom-color: var(--colorNavText);
        transition: right .5s;
    }

    .menu_list_tag:hover .menu_nav_tag:after {
        border-bottom-color: #333333;
        right: 0;
    }

}

@media(max-width:959px) {
    .footer_bottom_inner {
        width: calc(50% - 10px);
    }
}

@media(max-width: 768px) {
    .enquiry-inner-div .form_control::placeholder{
        color: #000;
        font-size: 14px;
    }
    .enquiry-inner-div{
        width: calc(100% - 30px);
        /* margin: 30px auto; */
    }
    .footer_bottom {
        padding: 0px 5px;
    }

    .podcasts-wrapper,
    .blog-wrapper {
        margin-top: 20px;
    }

    .blog-wrapper {
        margin-bottom: 30px;
    }

    .blog-subList {
        margin-top: 0;
    }

    .podcasts-head {
        font-size: 12px;
        margin-bottom: 12px;
    }

    .blog-head {
        font-size: 14px;
    }

    .icon_icon_user {
        display: none;
    }

    .menu_show_hide {
        display: block;
    }

    .inner_menus_head,
    .inner_menus_head:hover {
        text-transform: none;
        color: #66765f;
        font-family: "LibreFranklin-Regular";
        letter-spacing: 0;
        margin-bottom: 0;
        padding: 8px 25px 8px 0px;
        font-size: calc(15px*.92);
        transform: scaleY(1.05);
    }

    .inner_menu_mobile .inner_menus_head {
        flex: 1 1 auto;
        padding: 8px 25px 8px 0px;
        font-size: 14px;
        transform: scaleY(1.05)
    }

    .himalaya_globel .globe_tag {
        padding-left: 0;
        display: unset;
        padding-top: 10px;
        padding-bottom: 5px;
        font-size: 15px;
    }

    .himalaya_globel {
        margin-top: 25px;
    }

    .globe_tag_left {
        margin-left: 13px;
        display: block !important;
        margin-top: -12px;
        font-family: "LibreFranklin-Light";
    }

    .globe_tag.globe_tag_mobile img {
        vertical-align: top;
    }

    .dropdown_megamenu,
    .dropdown_single,
    .inner_dropdown_links {
        display: none;
    }

    .himalaya_globel,
    .header_social_div {
        display: flex;
    }

    .inner_menu_down_icon {
        display: block;
        transform: translateX(4px);
        flex: 0 0 28px;
    }

    .top_globel {
        display: none;
    }

    .inner_menu_mobile svg {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 1px solid #e8e8e1;
        color: #66765f;
        padding: 5px;
    }

    .inner_menu_mobile {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
    }

    .inner_menus_desktop {
        display: none;
    }

    .menu_listing_arrow_down {
        transform: rotate(180deg);
    }

    .menu_listing_arrow svg {
        width: 17px;
        height: 17px;
        color: #66765f;
    }

    .inner_menus_mobiles {
        display: block;
    }

    .menu_bar_close {
        width: 100%;
        padding: 11.53846px 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: end;
        padding: 24px 0px;
        padding-right: 20px;
    }

    .menu_listing_div {
        display: flex;
        align-items: center;
    }

    .menu_listing_div .menu_nav_tag {
        flex: 1 1 auto;
        padding: 15px 0px;
        font-size: 1.6em;
        transform: scaleY(1.05);
        letter-spacing: 0.02rem;
        word-spacing: -1px;
    }

    .globe_tag.globe_tag_mobile {
        padding: 0px 13px 0px 0;
        display: block;
        font-size: calc(15px *.92);
        transform: scaleY(1.05);
    }

    .menu_listing_div .menu_listing_arrow {
        flex: 0 1 auto;
        display: block;
        border-left: 1px solid;
        border-left-color: #e8e8e1;
        padding: 9px 0px 8px 22px;
        transition: .5s;
    }

    .main_footer_desktop_menu {
        display: none;
    }

    .store_location {
        padding-top: 20px;
    }

    .nav_link_tag,
    .submenu__isopen {
        text-decoration: none;
        align-items: center;
        transition: 0.5s;
        width: 100%;
        color: var(--footer-txt-color);
        white-space: nowrap;
        width: 100%;
        ;
        position: relative;
    }

    .submenu__isopen:hover {
        text-decoration: none;
        color: var(--footer-txt-color);
    }

    .selectSubmenu svg {
        width: 18px;
    }

    .footer_container .mobile_menu li {
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
    }

    .footer_container .subMenubar li {
        border-bottom: 0px;
    }

    .mobile_menu {
        display: block;
        font-size: 13px;
    }

    .store_locationform {
        margin-bottom: 30px;
        display: block;
    }

    .gmap_location_warapper {
        padding-right: 15px;
        padding-left: 15px;
    }

    .top_header .nav-link {
        font-size: 10px;
        font-family: "LibreFranklin-Regular";
    }

    .footer_bottom li {
        padding: 0px 10px;
    }

    .footer_bottom li:first-child::before {
        width: 0px;
    }

    .privacy_policy span::before {
        display: none;
    }

    .footer_para {
        width: 100%;
    }

    .footer_menu li .nav-link {
        padding: 10px 0px;
        font-family: "Inter-SemiBold";
        font-size: 12px;
    }

    .footer_link_head {
        font-size: 16px;
    }

    .main_menu li .nav-link {
        padding: 6px 5px;
        font-size: 12px;
    }

    .manin_menu_logo {
        width: 70px;
        height: 104px;
    }

    .manin_menu_logo_img {
        padding: 0;
    }

    .announcement_link_text {
        font-size: 11px;
    }

    .top_announcement {
        padding: 11px 0;
    }

    .body_wrapper {
        overflow: hidden;
    }

    .manin_menu_barList {
        position: fixed;
        top: 0;
        right: 0;
        margin-top: 0;
        z-index: 9999;
        height: 100%;
        width: 350px;
        transition: transform .25s cubic-bezier(.165, .84, .44, 1);
        transform: translate(350px);
        background: #fff;
        box-shadow: 0 0 150px #0000001a;
        padding: 20px;
        padding-top: 0;
        display: unset;
        padding-right: 0px;
    }

    .inner_menus {
        width: 100%;
        position: relative;
    }

    .inner_menus:last-child {
        padding-bottom: 10px;
    }

    .overflow_div {
        height: calc(100% - 69px);
        overflow: hidden;
        overflow-y: auto;
        display: block;
        padding-right: 20px;
    }

    .inner_dropdown_links {
        position: relative;
    }

    .announcement_text {
        font-size: 11px;
    }

    .inner_dropdown_links::before {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        background: #000;
        left: 0px;
        top: 6px;
        bottom: 6px;
    }

    .inner_menus .dropdown_link {
        padding: 7.5px 25px 7.5px 20px;
        color: #66765f;
    }

    .dropdown_single .dropdown_link {
        padding: 7.5px 25px 7.5px 0px;
    }

    .appear_animation {
        opacity: 1;
        transform: none;
    }

    .mobile_animation {
        opacity: 0;
        transform: translateY(60px);
    }

    .body_wrapper .mobile_animation {
        opacity: 1;
        transform: translateY(0);
    }

    .menu_list_tag {
        width: 100%;
        border-top: 1px solid;
        border-top-color: #e8e8e1;
    }

    .menu_list_tag:nth-child(3) {
        border-bottom: 1px solid;
        border-bottom-color: #e8e8e1;
    }

    .announcement_link {
        display: flex;
        flex-direction: column;
    }

    .main_menu.show .manin_menu_barList {
        left: unset;
        transform: translate(0px);
    }

    .footer_container .row.col-md-11.mx-auto,
    .footer_bottom .col-md-11.mx-auto {
        padding: 0;
    }

    .main_container {
        padding: 0px;
    }

    .manin_menu_menubar_ul {
        display: block;
        margin-left: auto;
    }

    .manin_menu_barList .nav-item {
        width: 100%;
    }

    .main_menu .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }

    .manin_menu_hemalaya {
        padding: 0px 17px;
    }

    .top_header_imgsrc {
        padding-right: 0px;
    }

    .top_header_right {
        margin-right: 0px;
    }

    .main_menu {
        padding: 0;
    }

    .service_categories_avilable li {
        width: 50%;
    }

    .reviews_mainWrapperinner {
        display: flex;
        overflow: auto;
        flex-direction: row;
        flex-wrap: nowrap;
        max-height: initial;
        gap: 15px;
        box-shadow: none;
        padding: 3px 3px;
    }

    .reviews_mainWrapper {
        box-shadow: none;
        padding-top: 0;
        margin-bottom: 0;
    }

    .button-theme {
        padding: 10px 25px;
        font-size: 12px;
        border-radius: 20px 0;
    }

    .reviews_btn {
        width: fit-content;
        margin-left: auto;
        min-width: 70%;
        border-radius: 15px 0;
    }

    .reviews_reviewBox {
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: #D9D9D9 #fff;
        width: 100%;
        height: 100%;
        padding-bottom: 0;
        border-bottom: 0;
        border-radius: 10px;
    }

    .reviews_reviewBox_inner {
        width: 230px;
        height: 220px;
        padding-bottom: 10px;
        background: #FFFFFF;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        flex-shrink: 0;
    }

    .reviews_reviewBox::-webkit-scrollbar {
        width: 4px;
        height: 0px;
    }

    .reviews_reviewBox::-webkit-scrollbar-track {
        background: #fff;
    }

    .reviews_reviewBox::-webkit-scrollbar-thumb {
        background: #D9D9D9;
    }

    .reviews_reviewBox::-webkit-scrollbar-thumb:hover {
        background: #fff;
    }

    .reviews_starRate,
    .reviews_imageTextDiv {
        width: 100%;
    }

    .reviews_starRate {
        margin-top: 10px;
    }

    .main-head,
    .reviews_main_head,
    .enquiry_main_head,
    .tag_main_head,
    .social_main_head,
    .youtube_main_head {
        margin-bottom: 20px;
        font-size: 18px;
    }

    .enquire_div {
        margin-bottom: 30px;
    }

    .youtube-wrapper {
        margin-bottom: 30px;
    }

    .social-media-img {
        width: 60px;
        height: 60px;
    }

    .social-media-img img {
        max-width: 24px;
    }

    .locator_lefthead {
        font-size: 20px;
    }

    .locator_stores {
        font-size: 12px;
        line-height: 1.5;
    }

    .tags-wrapper {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .social-media-wrapper {
        margin-bottom: 20px;
    }

    .reviews_mainWrapperinner {
        scrollbar-width: none;
        scrollbar-color: unset;
        padding: 10px 3px;
        padding-top: 5px;
    }

    .footer_container {
        background: #eef2f0;
    }

    .footer_container>.container {
        max-width: 100%;
    }

    .nav_sidemenu.mobile_menu .submenu__isopen {
        width: 100%;
        margin: 0;
        font-family: "LibreFranklin-Regular";
        padding: 14px 0px;
        text-transform: uppercase;
        display: inline-flex;
    }

    .footer_container .nav_sidemenu.mobile_menu {
        padding: 0px 0px;
        margin-bottom: 0;
    }

    .view_location_para {
        font-size: 14px;
    }

    .view_location_head {
        font-size: 18px;
    }

    .subMenubar {
        list-style: none;
        padding: 0;
        margin-bottom: 28px;
    }

    .subMenubar a {
        position: relative;
    }

    .locator_width .locator_listlileft {
        border: 1px solid #FF7451;
        box-shadow: none;
    }

    .locator_width {
        margin-left: 0;
        padding-left: 0;
    }

    .map_loop.reviewfull {
        width: 100%;
    }

    .reviews_reviewBox_inner.reviewfull {
        width: 100%;
        height: auto;
    }

    .reviewfull .reviews_reviewBox_inner {
        width: 100%;
        height: auto;
    }

    .reviews_empty_reviews_para {
        font-size: 14px;
        margin-bottom: 30px;
    }

    .review_mainWrapper.reviews_empty_reviews {
        padding: 40px 20px 21px 20px;
    }

    .locator_listlirightpara {
        font-family: 'LibreFranklin-Regular';
    }

    .youtube-iframe {
        width: 100%;
    }

    .youtube-subList iframe {
        width: 400px;
        height: 190px;
    }

    .footer_bottom {
        padding: 15px 2px;
        padding-bottom: 0;
    }

    .footer_bottom_inner {
        width: 100%;
        border-bottom: 1px solid #ddd;
    }

    .footer_nav_desk {
        display: none;
    }

    .footer_nav_mobile {
        display: flex;
        margin-bottom: 0;
        padding: 23px 0px 23px 2px;
        font-size: 11px;
        cursor: pointer;
        letter-spacing: 3px;
    }

    .footer_sub_div {
        display: none;
    }

    .footer_bottom_div {
        gap: 0;
    }

    .footer_container {
        padding: 0;
    }

    .main_banner {
        min-height: 115px;
    }
    .main_banner .slick-arrow::before {
        width: 8px;
        height: 8px;
    }
    .main_banner .slick-arrow.slick-next::before {
        right: 1px;
    }
    .main_banner .slick-arrow.slick-prev::before {
        left: 1px;
    }
    .main_banner .slick-arrow {
        width: 22px;
        height: 22px;
    }
    .error-head {
        font-size: 1.5rem;
    }
    .error-lead {
        font-size: 14px;
    }
}

@media(max-width: 576px) {
    .service_options ul li {
        width: 50%;
    }

    .social-media-img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
    }

    .social-media-img img {
        width: 25px;
        height: 25px;
    }

    .youtube-wrapper .slick-slide {
        width: 100%;
    }

    .youtube-subList iframe {
        width: 100%;
    }

    .youtube-wrapper .slick-track {
        gap: 0px;
    }
}

@media(max-width: 510px) {

    .locator_innerwidth,
    .locator_widthlast {
        width: 100%;
    }

    .tags-list-sub {
        min-width: 130px;
    }

    .locator_innerwidth {
        margin-bottom: 40px;
    }

    .locator_listlileft {
        width: 35px;
        height: 35px;
    }

    .locator_innerwidth,
    .locator_width {
        margin-bottom: 30px;
    }
}

@media(max-width:450px) {
    .podcasts-subList .button-sm {
        margin-top: unset;
    }
}