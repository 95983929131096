@media(max-width: 1199px){
    .input_form{
        width: calc(25% - 10px);
    }
    .admin_icons_left{
        padding-left: 0px;
    }
}
@media(max-width: 991px){
    .service_locator_option .adminIconMain{
        right: -40px;
    }
    .login_header{
        font-size: 25px;
    }
    .right_login,.left_login{
        padding: 50px 10px;
    }
    .input_form_btngroup{
        width: 50%;
    }
    .inner_cms_div{
        width: calc(50% - 10px);
    }
    .fileupload_headdiv{
        display: none;
    }
    .fileupload_bodydiv{
        flex-wrap: wrap;
    }
    .fileupload_body{
        width: 100%;
        display: flex;
    }
    .fileupload_body_head_span{
        display: block;
        color: var(--theme-black);
        font-family: "Poppins-Medium";
        opacity: 0.48;
        margin-right: 10px;
    }
    .edit-banner-section, .edit-section{
        height: fit-content;
    }
    .main_banner_list .edit-banner-section{
        height: fit-content;
    }
}
@media(max-width: 767px){
    .login_wrapper{
        flex-direction: column-reverse;
    }
    .right_login,.left_login{
        width: 100%;
    }
    .login_header{
        font-size: 20px;
    }
    .right_loginhead {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .input_form_btngroup{
        width: 100%;
    }
    .input_form{
        width: calc(50% - 10px);
    }
    .location_edit_divmain{
        flex-direction: column;
    }
    .file_upload_inputfile{
        flex-direction: column;
        gap: 10px;
    }
    .file_drag_drop{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .services_details{
        padding-left: 15px;
        padding-right: 15px;
    }
    .service_locator_option .adminIconMain.adminIconMainBtm{
        padding-right: 15px;
    }
}
@media(max-width: 576px){
    .right_login_inner, .right_login_forget{
        width: 100%;
    }
    .input_form{
        width:100%;
    }
    .inner_cms_div{
        width: 100%;
    }
    .cms_searchinput{
        padding: 10px 10px 10px 35px
    }
    .input-group-text {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 12px;
    }
    .cms_input_div{
        width: 250px;
    }
    .cms_searchicon{
        font-size: 16px;
    }
    .inner_cms_divhead,.inner_cms_divpara{
        font-size: 12px;
    }
    .inner_cms_div_img{
        width: 25px;
        height: 25px;
    }
    .locator_cms_ancher{
        padding: 4px;
    }
    .socialLinksAdmin{
        padding: 25px 5px;
    }
    .socialLinksList{
        margin-right: 0;
    }
    .head-social-help1 img{
        right: 5px;
    }
    .socialLinksAdmin label{
        font-size: 12px;
    }
    .editActive .fileUpload label{
        padding: 7px 15px;
        font-size: 12px;
    }
    .edit-banner-section, .edit-section{
        padding: 20px 10px;
    }
    .main_wrapper_cms .main_banner_list:nth-child(even) .adminIconMain.adminIconMainTop{
        padding-right: 30px;
    }
    .dashboard_logo{
        width: 80px;
    }
    .category_store_div.editActive:nth-child(odd) .edit-banner-section{
        left: unset !important;
        transform: translateY(-50%);
        right: unset !important;
    }
    .category_store_div.editActive:nth-child(even) .edit-banner-section{
        left: unset !important;
        transform: translateY(-50%);
        right: 0;
    }
    .main_wrapper_cms .service_options{
        padding-top: 80px;
    }
    .loder-list-add-edit{
        margin-top: 30px;
    }
}